import React, { useEffect } from 'react';
import { sendStatistic } from '../../utils/api';

function App() {
    useEffect(() => {
        const isOpenedApp = localStorage.getItem('pwa_open_app');
        sendStatistic('open_app', { is_first_open: isOpenedApp ? false : true, from: {
            url: window.location.href,
            device: 'browser',
            ref: document.referrer,
            is_pwa: window.matchMedia('(display-mode: standalone)').matches,
        } }, localStorage.getItem('clickid'));

        if (window.matchMedia('(display-mode: standalone)').matches) {
            if (!isOpenedApp) {
                localStorage.setItem('pwa_open_app', 'true');
            }
            window.location.href = 'https://luckygames.io/';
        } else {
            window.location.href = '/';
        }

        const intervalId = setInterval(() => {
            if (window.matchMedia('(display-mode: standalone)').matches) {
                if (!isOpenedApp) {
                    localStorage.setItem('pwa_open_app', 'true');
                }
                window.location.href = 'https://luckygames.io/';
            } else {
                window.location.href = '/';
            }
        }, 500);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <></>
    );
}

export default App;
