import React, { useEffect, useRef, useState } from 'react';
import styles from './VideoPlayer.module.css';
import { getOpenLink, sendStatistic } from '../../utils/api';
interface VideoPlayerProps {
    videoUrl: string;
    version: string;
}

const Arrow = () => {
    return (
        <svg style={{ width: '100px', height: '100px', color: 'red' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinejoin="round">
            <path d="M12 19V5M5 12l7-7 7 7" />
        </svg>
    )
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoUrl, version }) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [isPWAInstalled, setIsPWAInstalled] = useState<boolean>(false);
    const [isVideoBlocked, setIsVideoBlocked] = useState<boolean>(false);
    const [isPushRequested, setIsPushRequested] = useState<boolean>(false);
    const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
    const [showPushRequest, setShowPushRequest] = useState<boolean>(false);

    const [isPlaying, setIsPlaying] = useState<boolean>(false);

    const [arrowMode, setArrowMode] = useState<number>(0);

    const isDesktop = window.innerWidth > 768;

    const [link, setLink] = useState<string>('');

    // __________________________________________________________

    const openLink = () => {
        window.location.href = link;
    }

    // Проверка установки PWA
    const checkPWAInstallation = () => {
        if (deferredPrompt) {
            setIsPWAInstalled(true);
            setIsVideoBlocked(false);
            return;
        }
        setIsPWAInstalled(true);
        setIsVideoBlocked(false);

        sendStatistic('install_pwa', {
            from: {
                url: window.location.href,
                device: 'browser',
                ref: document.referrer,
                params: window.location.search
            }
        }, localStorage.getItem('clickid'));
    };

    // Запрос push-уведомлений
    const requestPushPermission = async () => {
        try {
            const permission = await Notification.requestPermission();
            if (permission === 'granted') {
                setIsPushRequested(true);
                setShowPushRequest(false);
                if (videoRef.current) {
                    videoRef.current.play();
                }
            } else {
                openLink();
            }
        } catch (error) {
            console.error('Ошибка при запросе разрешения:', error);
            openLink();
        }
        setArrowMode(0);
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('clickid');
        if (id) {
            localStorage.setItem('clickid', id);
        }

        const isInstalled = window.matchMedia('(display-mode: standalone)').matches;
        if (isInstalled) {
            setIsPWAInstalled(true);
            setIsVideoBlocked(false);
            openLink();
        }

        const handleBeforeInstallPrompt = (e: Event) => {
            e.preventDefault();
            setDeferredPrompt(e);
        };


        window.addEventListener('appinstalled', checkPWAInstallation);
        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        getOpenLink(version).then(data => {
            if (data.link) {
                setLink(data.link);
            }
        });
        sendStatistic('click', {
            from: {
                url: window.location.href,
                device: 'browser',
                ref: document.referrer,
                params: window.location.search,
            }
        }, localStorage.getItem('clickid'));


        return () => {
            window.removeEventListener('appinstalled', checkPWAInstallation);
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    useEffect(() => {
        const video = videoRef.current;
        if (!video) return;

        const preventFullScreen = (e: Event) => {
            e.preventDefault();
            console.log('preventFullScreen');
        };
        video.addEventListener('fullscreenchange', preventFullScreen);
        video.addEventListener('webkitfullscreenchange', preventFullScreen);
        video.addEventListener('mozfullscreenchange', preventFullScreen);
        video.addEventListener('msfullscreenchange', preventFullScreen);

        const handlePlay = () => setIsPlaying(true);
        const handlePause = () => setIsPlaying(false);
        video.addEventListener('play', handlePlay);
        video.addEventListener('pause', handlePause);

        return () => {
            video.removeEventListener('fullscreenchange', preventFullScreen);
            video.removeEventListener('webkitfullscreenchange', preventFullScreen);
            video.removeEventListener('mozfullscreenchange', preventFullScreen);
            video.removeEventListener('msfullscreenchange', preventFullScreen);

            video.removeEventListener('play', handlePlay);
            video.removeEventListener('pause', handlePause);
        };
    }, [videoRef]);

    useEffect(() => {
        const video = videoRef.current;
        if (!video) return;

        const handleTimeUpdate = () => {
            if (video.currentTime >= 10 && !isPWAInstalled) {
                video.pause();
                setIsVideoBlocked(true);
            }

            if (video.currentTime >= 17 && !isPushRequested) {
                video.pause();
                setArrowMode(2);
                requestPushPermission();
                setShowPushRequest(true);
            }

            if (video.currentTime >= video.duration - 10) {
                openLink();
            }
        };

        video.addEventListener('timeupdate', handleTimeUpdate);
        return () => {
            video.removeEventListener('timeupdate', handleTimeUpdate);
        };
    }, [isPWAInstalled, isPushRequested]);

    useEffect(() => {
        if (deferredPrompt) {
            setIsPWAInstalled(false);
        } else {
            setIsPWAInstalled(true);
            setIsVideoBlocked(false);
        }
    }, [deferredPrompt]);

    const installPWA = async () => {
        if (!deferredPrompt) return;

        try {
            const result = await deferredPrompt.prompt();
            setArrowMode(1);

            await result.userChoice;
            setArrowMode(0);

            setDeferredPrompt(null);
        } catch (error) {
            console.error('Ошибка при установке PWA:', error);
        }
    };

    const handleRewind = (seconds: number) => {
        if (videoRef.current) {
            videoRef.current.currentTime = Math.max(0, videoRef.current.currentTime - seconds);
        }
    };

    const handleForward = (seconds: number) => {
        if (videoRef.current) {
            videoRef.current.currentTime = Math.min(videoRef.current.duration, videoRef.current.currentTime + seconds);
        }
    };
    const handlePlayPause = () => {
        if (videoRef.current) {
            if (videoRef.current.paused) {
                videoRef.current.play();
            } else {
                videoRef.current.pause();
            }
        }
    };

    return (
        <div className={styles.videoContainer}>
            {arrowMode === 1 && (
                <div style={{ zIndex: 999 }} className={!isDesktop ? styles.arrow1 : styles.arrowDesktop}>
                    <Arrow />
                </div>
            )}
            {arrowMode === 2 && (
                <div style={{ zIndex: 999 }} className={!isDesktop ? styles.arrow2 : styles.arrowDesktop}>
                    <Arrow />
                </div>
            )}
            <video
                ref={videoRef}
                src={videoUrl}
                controls={false}
                className={`${styles.video} ${isVideoBlocked ? styles.blurred : ''}`}
                controlsList='nodownload'
            />
            <div className={styles.customControls}>
                <button onClick={() => handleRewind(10)}>⏪ 10 sec</button>
                <button onClick={handlePlayPause}>
                    {!isPlaying ? '▶️' : '⏸️'}
                </button>
                <button onClick={() => handleForward(10)}>10 sec ⏩</button>
            </div>
            {isVideoBlocked && !isPWAInstalled && (
                <div className={styles.overlay}>
                    <p>Please install the app to continue watching</p>
                    <button
                        className={styles.installButton}
                        onClick={() => {
                            setArrowMode(1);
                            installPWA();
                        }}
                    >
                        Continue viewing
                    </button>
                </div>
            )}
            {showPushRequest && !isPushRequested && (
                <div className={styles.overlay}>
                    <div className={styles.overlayContent}>
                        <p>Please allow push notifications to continue watching</p>
                        <button
                            className={styles.actionButton}
                            onClick={() => {
                                if (deferredPrompt) setArrowMode(2);
                                requestPushPermission();
                            }}
                        >
                            Allow notifications
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VideoPlayer;