import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeCaptcha from './pages/HomeCaptcha/HomeCaptcha';
import HomeVideo from './pages/HomeVideo/HomeVideo';
import OpenApp from './pages/App/App';

function App() {
    return (
        <Router>
            {/* <Header /> */}
            <Routes>
                <Route path="/ca/:version" element={<HomeCaptcha />} />
                <Route path="/ca/" element={<HomeCaptcha />} />
                <Route path="/app" element={<OpenApp />} />
                <Route path="/:version" element={<HomeVideo />} />
                <Route path="/" element={<HomeVideo />} />

            </Routes>
            {/* <Footer /> */}
        </Router>
    );
}

export default App;
