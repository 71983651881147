import { useEffect, useRef, useState } from 'react';
import './HomeVideo.css';
import { useParams } from "react-router-dom";
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';

const HomeVideo = () => {

    const { version } = useParams<{ version: string }>();
    
    useEffect(() => {
        
    }, []);

    return (
        <div className='home-video'>
          <h1>Home video</h1>
          <VideoPlayer videoUrl="/v1.mp4" version={version || ''} />
        </div>
      );
}

export default HomeVideo;