import './ArrowInstall.css';
import { ReactComponent as Arrow } from '../../assets/arrow.svg';

export default function ArrowInstall({ isMobile }: { isMobile: boolean }) {
    return (
        isMobile ? (
            <div className="arrow-install">
                <Arrow className="arrow-install-image" />
            </div>
        ) : (
            <div className="arrow-install-desktop">
                <Arrow className="arrow-install-image" />
            </div>
        )
    );
}