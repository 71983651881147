export const sendStatistic = async (event: string, data: object = {}, clickId: string | null = null) => {
    fetch('/api/pwa/statistic', {
        method: 'POST',
        body: JSON.stringify({
            url: window.location.href,
            event: event,
            data: data,
            clickId: clickId
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export const getOpenLink = async (appVersion: string) : Promise<{ link: string | null, use_arrow: boolean }> => {
    try {
        const response = await fetch('/api/get/open-link', {
            method: 'POST',
            body: JSON.stringify({
                version: appVersion
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
        return { link: data.link ?? null, use_arrow: data.use_arrow ?? false };
    } catch (error) {
        return { link: null, use_arrow: false };
    }
}
